import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const checkTokenExists = () => {
  const token = localStorage.getItem("token");
  return !!token;
};

const routes: Array<RouteRecordRaw> = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    beforeEnter: (to, from, next) => {
      if (checkTokenExists()) next("/");
      else next();
    },
  },
  {
    path: "/",
    component: () => import("@/App.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: () => import("@/components/dashboard/index.vue"),
        meta: {
          breadcrumb: [{ parent: "Home", label: "Dashboard" }],
        },
      },
      {
        path: "/categories",
        name: "Categories",
        component: () => import("@/components/categories/index.vue"),
        meta: {
          breadcrumb: [{ parent: "Management", label: "Categories" }],
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// route guard
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (checkTokenExists()) next();
    else next("/login");
  } else {
    next();
  }
});

export default router;
